import * as React from "react"
import Layout from "../components/layout"
// import { Link } from "gatsby"
import { graphql } from 'gatsby';
import Img from "gatsby-image"

import Slider from "react-slick";
import MediaQuery from 'react-responsive'

const Catalog = ({ data }) => {
    const catalogdate = data.datoCmsCatalog
    
    const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      dots: true,
      customPaging: function(i) {
          return (
            <button>
              <Img fluid={catalogdate.image2[i].fluid} />
            </button>
          );
      },
    };

    const settings_sp = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      customPaging: function(i) {
          return (
            <button>
              <Img fluid={catalogdate.image[i].fluid} />
            </button>
          );
      },
    };
  return (
    <Layout >
        <MediaQuery minDeviceWidth={601}>
          <Slider {...settings} className="catalog_slick">
          {catalogdate.image.map( (e, index )=>{
                            return(
                              <div key={index}>
                                <Img fluid={e.fluid} />
                              </div>
                            )
                          })}       
          </Slider>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={600}>
        <Slider {...settings_sp} className="catalog_slick">
          {catalogdate.image.map( (e, index )=>{
                            return(
                              <div key={index}>
                                <Img fluid={e.fluid} />
                              </div>
                            )
                          })}       
          </Slider>
        </MediaQuery>
    </Layout>
  )
}

export default Catalog


export const query = graphql`
 query {
    datoCmsCatalog{
        seo {
          description
          title
          image {
            url
          }
        }
        image{
            fluid(maxWidth: 800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
                ...GatsbyDatoCmsFluid
              }
        }
        image2{
            fluid(maxWidth: 200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
                ...GatsbyDatoCmsFluid
              }
        }
      }
 }

`
